/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GondolaMappedCountry } from './GondolaMappedCountry';
import {
    GondolaMappedCountryFromJSON,
    GondolaMappedCountryFromJSONTyped,
    GondolaMappedCountryToJSON,
} from './GondolaMappedCountry';

/**
 * 
 * @export
 * @interface GondolaMappedCountries
 */
export interface GondolaMappedCountries {
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedCountries
     */
    percentageMoreThanOthers?: number;
    /**
     * 
     * @type {Array<GondolaMappedCountry>}
     * @memberof GondolaMappedCountries
     */
    items: Array<GondolaMappedCountry>;
}

/**
 * Check if a given object implements the GondolaMappedCountries interface.
 */
export function instanceOfGondolaMappedCountries(value: object): value is GondolaMappedCountries {
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function GondolaMappedCountriesFromJSON(json: any): GondolaMappedCountries {
    return GondolaMappedCountriesFromJSONTyped(json, false);
}

export function GondolaMappedCountriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedCountries {
    if (json == null) {
        return json;
    }
    return {
        
        'percentageMoreThanOthers': json['percentage_more_than_others'] == null ? undefined : json['percentage_more_than_others'],
        'items': ((json['items'] as Array<any>).map(GondolaMappedCountryFromJSON)),
    };
}

export function GondolaMappedCountriesToJSON(value?: GondolaMappedCountries | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'percentage_more_than_others': value['percentageMoreThanOthers'],
        'items': ((value['items'] as Array<any>).map(GondolaMappedCountryToJSON)),
    };
}

