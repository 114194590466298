/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GondolaMappedCountry
 */
export interface GondolaMappedCountry {
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedCountry
     */
    countryCode: string;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedCountry
     */
    year: number;
}

/**
 * Check if a given object implements the GondolaMappedCountry interface.
 */
export function instanceOfGondolaMappedCountry(value: object): value is GondolaMappedCountry {
    if (!('countryCode' in value) || value['countryCode'] === undefined) return false;
    if (!('year' in value) || value['year'] === undefined) return false;
    return true;
}

export function GondolaMappedCountryFromJSON(json: any): GondolaMappedCountry {
    return GondolaMappedCountryFromJSONTyped(json, false);
}

export function GondolaMappedCountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedCountry {
    if (json == null) {
        return json;
    }
    return {
        
        'countryCode': json['country_code'],
        'year': json['year'],
    };
}

export function GondolaMappedCountryToJSON(value?: GondolaMappedCountry | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'country_code': value['countryCode'],
        'year': value['year'],
    };
}

