/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * 
 * @export
 * @interface GondolaMappedBiggestFlightPointsEarnedOrRedeemed
 */
export interface GondolaMappedBiggestFlightPointsEarnedOrRedeemed {
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedBiggestFlightPointsEarnedOrRedeemed
     */
    flightDepartureIata?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedBiggestFlightPointsEarnedOrRedeemed
     */
    flightArrivalIata?: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof GondolaMappedBiggestFlightPointsEarnedOrRedeemed
     */
    flightDepartureCoordinates?: Coordinates;
    /**
     * 
     * @type {Coordinates}
     * @memberof GondolaMappedBiggestFlightPointsEarnedOrRedeemed
     */
    flightArrivalCoordinates?: Coordinates;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedBiggestFlightPointsEarnedOrRedeemed
     */
    points?: number;
}

/**
 * Check if a given object implements the GondolaMappedBiggestFlightPointsEarnedOrRedeemed interface.
 */
export function instanceOfGondolaMappedBiggestFlightPointsEarnedOrRedeemed(value: object): value is GondolaMappedBiggestFlightPointsEarnedOrRedeemed {
    return true;
}

export function GondolaMappedBiggestFlightPointsEarnedOrRedeemedFromJSON(json: any): GondolaMappedBiggestFlightPointsEarnedOrRedeemed {
    return GondolaMappedBiggestFlightPointsEarnedOrRedeemedFromJSONTyped(json, false);
}

export function GondolaMappedBiggestFlightPointsEarnedOrRedeemedFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedBiggestFlightPointsEarnedOrRedeemed {
    if (json == null) {
        return json;
    }
    return {
        
        'flightDepartureIata': json['flight_departure_iata'] == null ? undefined : json['flight_departure_iata'],
        'flightArrivalIata': json['flight_arrival_iata'] == null ? undefined : json['flight_arrival_iata'],
        'flightDepartureCoordinates': json['flight_departure_coordinates'] == null ? undefined : CoordinatesFromJSON(json['flight_departure_coordinates']),
        'flightArrivalCoordinates': json['flight_arrival_coordinates'] == null ? undefined : CoordinatesFromJSON(json['flight_arrival_coordinates']),
        'points': json['points'] == null ? undefined : json['points'],
    };
}

export function GondolaMappedBiggestFlightPointsEarnedOrRedeemedToJSON(value?: GondolaMappedBiggestFlightPointsEarnedOrRedeemed | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'flight_departure_iata': value['flightDepartureIata'],
        'flight_arrival_iata': value['flightArrivalIata'],
        'flight_departure_coordinates': CoordinatesToJSON(value['flightDepartureCoordinates']),
        'flight_arrival_coordinates': CoordinatesToJSON(value['flightArrivalCoordinates']),
        'points': value['points'],
    };
}

