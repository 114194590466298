/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GondolaMappedFlights } from './GondolaMappedFlights';
import {
    GondolaMappedFlightsFromJSON,
    GondolaMappedFlightsFromJSONTyped,
    GondolaMappedFlightsToJSON,
} from './GondolaMappedFlights';
import type { GondolaMappedLongestHotelStay } from './GondolaMappedLongestHotelStay';
import {
    GondolaMappedLongestHotelStayFromJSON,
    GondolaMappedLongestHotelStayFromJSONTyped,
    GondolaMappedLongestHotelStayToJSON,
} from './GondolaMappedLongestHotelStay';
import type { GondolaMappedCities } from './GondolaMappedCities';
import {
    GondolaMappedCitiesFromJSON,
    GondolaMappedCitiesFromJSONTyped,
    GondolaMappedCitiesToJSON,
} from './GondolaMappedCities';
import type { GondolaMappedCountries } from './GondolaMappedCountries';
import {
    GondolaMappedCountriesFromJSON,
    GondolaMappedCountriesFromJSONTyped,
    GondolaMappedCountriesToJSON,
} from './GondolaMappedCountries';
import type { GondolaMappedPoints } from './GondolaMappedPoints';
import {
    GondolaMappedPointsFromJSON,
    GondolaMappedPointsFromJSONTyped,
    GondolaMappedPointsToJSON,
} from './GondolaMappedPoints';

/**
 * 
 * @export
 * @interface GondolaMappedResponse
 */
export interface GondolaMappedResponse {
    /**
     * 
     * @type {GondolaMappedCountries}
     * @memberof GondolaMappedResponse
     */
    countries: GondolaMappedCountries;
    /**
     * 
     * @type {GondolaMappedCities}
     * @memberof GondolaMappedResponse
     */
    cities: GondolaMappedCities;
    /**
     * 
     * @type {GondolaMappedFlights}
     * @memberof GondolaMappedResponse
     */
    flights: GondolaMappedFlights;
    /**
     * 
     * @type {GondolaMappedLongestHotelStay}
     * @memberof GondolaMappedResponse
     */
    longestHotelStay?: GondolaMappedLongestHotelStay;
    /**
     * 
     * @type {GondolaMappedPoints}
     * @memberof GondolaMappedResponse
     */
    yearEndPoints: GondolaMappedPoints;
    /**
     * 
     * @type {GondolaMappedPoints}
     * @memberof GondolaMappedResponse
     */
    pointsRedeemed: GondolaMappedPoints;
}

/**
 * Check if a given object implements the GondolaMappedResponse interface.
 */
export function instanceOfGondolaMappedResponse(value: object): value is GondolaMappedResponse {
    if (!('countries' in value) || value['countries'] === undefined) return false;
    if (!('cities' in value) || value['cities'] === undefined) return false;
    if (!('flights' in value) || value['flights'] === undefined) return false;
    if (!('yearEndPoints' in value) || value['yearEndPoints'] === undefined) return false;
    if (!('pointsRedeemed' in value) || value['pointsRedeemed'] === undefined) return false;
    return true;
}

export function GondolaMappedResponseFromJSON(json: any): GondolaMappedResponse {
    return GondolaMappedResponseFromJSONTyped(json, false);
}

export function GondolaMappedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'countries': GondolaMappedCountriesFromJSON(json['countries']),
        'cities': GondolaMappedCitiesFromJSON(json['cities']),
        'flights': GondolaMappedFlightsFromJSON(json['flights']),
        'longestHotelStay': json['longest_hotel_stay'] == null ? undefined : GondolaMappedLongestHotelStayFromJSON(json['longest_hotel_stay']),
        'yearEndPoints': GondolaMappedPointsFromJSON(json['year_end_points']),
        'pointsRedeemed': GondolaMappedPointsFromJSON(json['points_redeemed']),
    };
}

export function GondolaMappedResponseToJSON(value?: GondolaMappedResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'countries': GondolaMappedCountriesToJSON(value['countries']),
        'cities': GondolaMappedCitiesToJSON(value['cities']),
        'flights': GondolaMappedFlightsToJSON(value['flights']),
        'longest_hotel_stay': GondolaMappedLongestHotelStayToJSON(value['longestHotelStay']),
        'year_end_points': GondolaMappedPointsToJSON(value['yearEndPoints']),
        'points_redeemed': GondolaMappedPointsToJSON(value['pointsRedeemed']),
    };
}

