/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GondolaMappedBiggestFlightPointsEarnedOrRedeemed } from './GondolaMappedBiggestFlightPointsEarnedOrRedeemed';
import {
    GondolaMappedBiggestFlightPointsEarnedOrRedeemedFromJSON,
    GondolaMappedBiggestFlightPointsEarnedOrRedeemedFromJSONTyped,
    GondolaMappedBiggestFlightPointsEarnedOrRedeemedToJSON,
} from './GondolaMappedBiggestFlightPointsEarnedOrRedeemed';
import type { GondolaMappedBiggestHotelPointsEarnedOrRedeemed } from './GondolaMappedBiggestHotelPointsEarnedOrRedeemed';
import {
    GondolaMappedBiggestHotelPointsEarnedOrRedeemedFromJSON,
    GondolaMappedBiggestHotelPointsEarnedOrRedeemedFromJSONTyped,
    GondolaMappedBiggestHotelPointsEarnedOrRedeemedToJSON,
} from './GondolaMappedBiggestHotelPointsEarnedOrRedeemed';

/**
 * 
 * @export
 * @interface GondolaMappedPoints
 */
export interface GondolaMappedPoints {
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedPoints
     */
    gondolaPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedPoints
     */
    hotelPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedPoints
     */
    creditCardPoints?: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedPoints
     */
    flightPoints?: number;
    /**
     * 
     * @type {GondolaMappedBiggestHotelPointsEarnedOrRedeemed}
     * @memberof GondolaMappedPoints
     */
    biggestHotelPointsEarnedOrRedeemed?: GondolaMappedBiggestHotelPointsEarnedOrRedeemed;
    /**
     * 
     * @type {GondolaMappedBiggestFlightPointsEarnedOrRedeemed}
     * @memberof GondolaMappedPoints
     */
    biggestFlightPointsEarnedOrRedeemed?: GondolaMappedBiggestFlightPointsEarnedOrRedeemed;
}

/**
 * Check if a given object implements the GondolaMappedPoints interface.
 */
export function instanceOfGondolaMappedPoints(value: object): value is GondolaMappedPoints {
    return true;
}

export function GondolaMappedPointsFromJSON(json: any): GondolaMappedPoints {
    return GondolaMappedPointsFromJSONTyped(json, false);
}

export function GondolaMappedPointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedPoints {
    if (json == null) {
        return json;
    }
    return {
        
        'gondolaPoints': json['gondola_points'] == null ? undefined : json['gondola_points'],
        'hotelPoints': json['hotel_points'] == null ? undefined : json['hotel_points'],
        'creditCardPoints': json['credit_card_points'] == null ? undefined : json['credit_card_points'],
        'flightPoints': json['flight_points'] == null ? undefined : json['flight_points'],
        'biggestHotelPointsEarnedOrRedeemed': json['biggest_hotel_points_earned_or_redeemed'] == null ? undefined : GondolaMappedBiggestHotelPointsEarnedOrRedeemedFromJSON(json['biggest_hotel_points_earned_or_redeemed']),
        'biggestFlightPointsEarnedOrRedeemed': json['biggest_flight_points_earned_or_redeemed'] == null ? undefined : GondolaMappedBiggestFlightPointsEarnedOrRedeemedFromJSON(json['biggest_flight_points_earned_or_redeemed']),
    };
}

export function GondolaMappedPointsToJSON(value?: GondolaMappedPoints | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gondola_points': value['gondolaPoints'],
        'hotel_points': value['hotelPoints'],
        'credit_card_points': value['creditCardPoints'],
        'flight_points': value['flightPoints'],
        'biggest_hotel_points_earned_or_redeemed': GondolaMappedBiggestHotelPointsEarnedOrRedeemedToJSON(value['biggestHotelPointsEarnedOrRedeemed']),
        'biggest_flight_points_earned_or_redeemed': GondolaMappedBiggestFlightPointsEarnedOrRedeemedToJSON(value['biggestFlightPointsEarnedOrRedeemed']),
    };
}

