/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';

/**
 * 
 * @export
 * @interface GondolaMappedFlights
 */
export interface GondolaMappedFlights {
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedFlights
     */
    totalNumberOfFlights?: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedFlights
     */
    totalNumberOfFlightsThisYear?: number;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedFlights
     */
    percentageMoreThanOthers?: number;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedFlights
     */
    longestFlightDepartureIata?: string;
    /**
     * 
     * @type {string}
     * @memberof GondolaMappedFlights
     */
    longestFlightArrivalIata?: string;
    /**
     * 
     * @type {number}
     * @memberof GondolaMappedFlights
     */
    longestFlightDurationInSeconds?: number;
    /**
     * 
     * @type {Coordinates}
     * @memberof GondolaMappedFlights
     */
    longestFlightDepartureCoordinates?: Coordinates;
    /**
     * 
     * @type {Coordinates}
     * @memberof GondolaMappedFlights
     */
    longestFlightArrivalCoordinates?: Coordinates;
}

/**
 * Check if a given object implements the GondolaMappedFlights interface.
 */
export function instanceOfGondolaMappedFlights(value: object): value is GondolaMappedFlights {
    return true;
}

export function GondolaMappedFlightsFromJSON(json: any): GondolaMappedFlights {
    return GondolaMappedFlightsFromJSONTyped(json, false);
}

export function GondolaMappedFlightsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GondolaMappedFlights {
    if (json == null) {
        return json;
    }
    return {
        
        'totalNumberOfFlights': json['total_number_of_flights'] == null ? undefined : json['total_number_of_flights'],
        'totalNumberOfFlightsThisYear': json['total_number_of_flights_this_year'] == null ? undefined : json['total_number_of_flights_this_year'],
        'percentageMoreThanOthers': json['percentage_more_than_others'] == null ? undefined : json['percentage_more_than_others'],
        'longestFlightDepartureIata': json['longest_flight_departure_iata'] == null ? undefined : json['longest_flight_departure_iata'],
        'longestFlightArrivalIata': json['longest_flight_arrival_iata'] == null ? undefined : json['longest_flight_arrival_iata'],
        'longestFlightDurationInSeconds': json['longest_flight_duration_in_seconds'] == null ? undefined : json['longest_flight_duration_in_seconds'],
        'longestFlightDepartureCoordinates': json['longest_flight_departure_coordinates'] == null ? undefined : CoordinatesFromJSON(json['longest_flight_departure_coordinates']),
        'longestFlightArrivalCoordinates': json['longest_flight_arrival_coordinates'] == null ? undefined : CoordinatesFromJSON(json['longest_flight_arrival_coordinates']),
    };
}

export function GondolaMappedFlightsToJSON(value?: GondolaMappedFlights | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'total_number_of_flights': value['totalNumberOfFlights'],
        'total_number_of_flights_this_year': value['totalNumberOfFlightsThisYear'],
        'percentage_more_than_others': value['percentageMoreThanOthers'],
        'longest_flight_departure_iata': value['longestFlightDepartureIata'],
        'longest_flight_arrival_iata': value['longestFlightArrivalIata'],
        'longest_flight_duration_in_seconds': value['longestFlightDurationInSeconds'],
        'longest_flight_departure_coordinates': CoordinatesToJSON(value['longestFlightDepartureCoordinates']),
        'longest_flight_arrival_coordinates': CoordinatesToJSON(value['longestFlightArrivalCoordinates']),
    };
}

